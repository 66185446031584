@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 255, 255, 255;
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb));
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded transition-colors;
  }

  .btn-primary {
    @apply bg-blue-500 text-white hover:bg-blue-600;
  }

  .btn-secondary {
    @apply border hover:border-blue-500;
  }

  .input {
    @apply border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500;
  }
} 